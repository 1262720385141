<template>
  <div class="col-12 c g">
    <div class="card card-body">
        <div class="table-responsive col-12">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        الطالب
                    </th>
                    <th>
                        اسم ولي الامر	
                    </th>
                    <th>
                        هاتف ولي الامر	
                    </th>
                    <th>
                        التاريخ	
                    </th>
                    <th>
                        صلة القرابة	
                    </th>
                    <th>
                        ملاحظات	
                    </th>
                    <th>
                        الحالة
                    </th>
                    <th>
                        كود التأكيد	
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="request in requests" :key="request._id">
                        <td>
                            {{ request.student_name }}
                        </td>
                        <td>
                            {{ request.name }}
                        </td>
                        <td>
                            {{ request.phone }}
                        </td>
                        <td>
                            {{ request.date }}
                        </td>
                        <td>
                            {{ request.relation }}
                        </td>
                        <td>
                            {{ request.notes }}
                        </td>
                        <td>
                            <span v-if="request.status == 0" class="btn btn-sm btn-warning">في الانتظار</span>
                            <span v-if="request.status == 1" class="btn btn-sm btn-success">تمت الموافقة</span>
                            <span v-if="request.status == 2">مرفوض</span>
                        </td>
                        <td>
                            {{ request.code }}
                        </td>
                        <td>
                            <button class="btn btn-sm btn-success" style="border-radius: 0px" @click="accept(request._id)">
                                <i class="fa fa-check"></i> موافقة
                            </button>
                            <button class="btn btn-sm btn-outline-trash" style="border-radius: 0px" @click="remove(request._id)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        vSelect
    },
    directives: {
        Ripple
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            requests: []
        }
    },
    created(){
        if(!checkPer("calls")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getR()
    },
    methods: {
        getR(){
            var g = this;
            $.post(api + '/user/calls/requests', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.requests = r.response
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        accept(id){
            var g = this;
            $.post(api + '/user/calls/requests/accept', {
                jwt: this.user.jwt,
                id: id
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getR()
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        remove(id){
            if(confirm("متأكد من الحذف؟")){
                var g = this;
                $.post(api + '/user/calls/requests/delete', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getR()
                    }
                }).fail(function(){
                    g.loading = false;
                })
            }
        }
    }
}
</script>
